import { gql, request } from "graphql-request"
import { StationAttributes } from "graphql/stationAttributes"
import { useInfiniteQuery } from "react-query"

// Query name
export const stationsQueryName = "stationsQuery"

// GraphQL query
const query = gql`
  ${StationAttributes()}

  query StationsQuery($pageSize: Int!, $offset: Int!) {
    stations(
      pageSize: $pageSize
      offset: $offset
      orderBy: [{ key: "title", direction: ASC }]
    ) {
      nodes {
        ...StationAttributes
      }
      pageInfo {
        hasMore
      }
    }
  }
`

//
// Query for stations from the content system
//
export const stationsQuery = async ({ queryKey, pageParam = 0 }) => {
  const [, { breakpoint }] = queryKey
  let pageSize = 0
  switch (breakpoint) {
    case "desktop":
    case "laptop":
      pageSize = 24
      break
    case "tablet":
      pageSize = 18
      break
    case "mobile":
      pageSize = 12
      break
    default:
      throw new Error(`Unrecognised breakpoint: ${breakpoint}`)
  }
  try {
    // Run GraphQL query to get content items
    const stations = await request(
      process.env.GATSBY_GRAPHQL_CONTENT_ENDPOINT,
      query,
      {
        pageSize: pageSize,
        offset: pageParam,
      }
    )
    return stations
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use the stations content query
//
export const useStationsQuery = (breakpoint) => {
  return useInfiniteQuery([stationsQueryName, { breakpoint }], stationsQuery, {
    staleTime: Infinity,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.stations?.pageInfo?.hasMore) {
        return pages.length * lastPage.stations.nodes.length
      } else {
        return undefined
      }
    },
    enabled: breakpoint !== undefined,
  })
}
