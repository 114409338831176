import { useLocation } from "@reach/router"
import ContentCard from "components/contentCard/contentCard"
import Button from "components/controls/button"
import Metadata from "components/utils/metadata/metadata"
import ModalDialog from "components/utils/modalDialog"
import Spinner from "components/utils/spinner"
import BreakpointContext from "context/breakpointContext"
import { ContentItemsProvider } from "context/contentItemsContext"
import { Screen, useSetScreen } from "context/screenContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import { useFooter } from "hooks/useFooter"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useTabBar } from "hooks/useTabBAr"
import GridLayout from "layouts/gridLayout"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useStationsQuery } from "reactQuery/queries/stationsQuery"

const StoreStations = ({ pageContext }) => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Query for stations
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useStationsQuery(bpt)

  // Join all pages of stations together
  const stations = useMemo(() => {
    let stations = []
    stations = data?.pages?.flatMap((page) => {
      return page.stations?.nodes?.flatMap((station) => {
        return station
      })
    })
    return stations
  }, [data])

  // Build a map of station content items
  const contentItemsMap = useContentItemsMap(stations)

  // Error state
  const [showError, setShowError] = useState(false)

  // Mount state
  const isMounted = useMountOnLoad()

  // Try and extract the selected item from the page context, if
  // this page has been built statically.
  const selectedItem = pageContext?.selectedItem

  // Generate the page title
  const pageTitle = useMemo(() => {
    if (selectedItem) {
      return `${selectedItem.title} | Radio`
    } else {
      return "Radio"
    }
  }, [selectedItem])

  // Use cover art for the metadata image if there is a selected item
  const metadataImage = useMemo(() => {
    if (selectedItem && selectedItem.artwork) {
      return selectedItem.artwork
    } else {
      return undefined
    }
  }, [selectedItem])

  // Only display footer when the stations have finished loading
  useFooter(isMounted && data !== undefined)

  // Use a Tab Bar
  useTabBar(true)

  useEffect(() => {
    if (error) {
      setShowError(true)
    }
  }, [error])

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata
        title={pageTitle}
        description={selectedItem ? selectedItem.description : undefined}
        image={metadataImage}
        pathname={location.pathname}
        useStrapLine={selectedItem ? false : true}
      />

      {/* Content */}
      <GridLayout
        type="fluid"
        container={false}
        offsets={false}
        className="col-span-full"
      >
        <ContentItemsProvider contentItemsMap={contentItemsMap}>
          {/* Stations */}
          {stations?.map((station) => {
            return <ContentCard key={station.ref} contentRef={station.ref} />
          })}
        </ContentItemsProvider>
        <div className="col-span-full flex justify-center">
          {isMounted && data && (
            <Button
              text="More stations"
              shadowHeight={1}
              size="sm"
              loading={isFetchingNextPage}
              disabled={!hasNextPage}
              onClick={() => fetchNextPage()}
              className="w-52 mt-5"
            />
          )}
        </div>

        {/* Initial loading spinner */}
        <Spinner
          isLoading={isFetching && !isFetchingNextPage}
          className="absolute top-24"
        />
      </GridLayout>

      {showError && (
        <ModalDialog
          onPrimaryButtonClicked={() => {
            setShowError(false)
          }}
          message={
            <>
              <p>
                We're sorry but we couldn't load radio stations from the store
                at this time.
              </p>
              <p className="mt-2">
                Please try again by refreshing your browser or contact our
                customer support.
              </p>
            </>
          }
        />
      )}
    </>
  )
}

export default StoreStations
StoreStations.whyDidYouRender = true
